/* ==================================================================================================================================
* Dashboard WhatsApp WEB - Gerenciador de mensagens
* ==================================================================================================================================
* Copyright (C) 2010 - 2023
* Programador: Fabio P. vilas Boas 
*
*  - fabio.2705@hotmail.com
*  - multi-clinicas@hotmail.com
*  - multiclinicas.suporte@gmail.com
*  - Skype: Multi Clinicas
*
* Modulo Login do Sistema
*
* Projeto Original
* https://github.com/canove/whaticket
* Coded by WhatsApp WEB
* ----------------------------------------------------------------------------------------------------------------------------------
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.*/ 
import React, { useState, useContext } from "react";
//import { Link as RouterLink } from "react-router-dom";
import { Button, CssBaseline, TextField, Box, Typography, Container, InputAdornment, IconButton, Link } from '@material-ui/core';

import { Visibility, VisibilityOff, Email } from '@material-ui/icons';
import { makeStyles } from "@material-ui/core/styles";
import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import CheckBoxIcon from "@material-ui/icons/CheckCircle";
import { versionSystem } from "../../../package.json";
import Logomarca from '../../assets/logomarca.png';

const Copyright = () => {
  	return (
   	<Typography variant="body2" color="textSecondary" align="center">
      	© {new Date().getFullYear()}
      	{" - "}
      	<Link href="javascripit:void(0)" color="inherit">
				API WhatsApp - v {versionSystem}<br /> All rights reserved
      	</Link>
      	{"."}
    	</Typography>
  	);
};

const useStyles = makeStyles((theme) => ({
  	paper: {
   	marginTop: theme.spacing(8),
    	display: "flex",
    	flexDirection: "column",
    	alignItems: "center",
    	backgroundColor: "inherit",
  	},
  	form: {
   	width: "100%", // Fix IE 11 issue.
    	marginTop: theme.spacing(1),
  	},
  	submit: {
   	margin: theme.spacing(3, 0, 2),
  	},
}));

const Login = () => {
  	const classes = useStyles();
  	const [user, setUser] = useState({ email: "", password: "" });
  	const [showPassword, setShowPassword] = useState(false);
  	const { handleLogin } = useContext(AuthContext);
  	const handleChangeInput = (e) => {
   	setUser({ ...user, [e.target.name]: e.target.value });
  	};
  
  	const handlSubmit = (e) => {
   	e.preventDefault();
    	handleLogin(user);
  	};

  	return (
   	<Container component="main" maxWidth="xs" 
   		style={{
   			marginTop: "50px",
   			backgroundColor: "white",
   			border: "1px solid #eee",
   			boxShadow: "0px 15px 25px 0px rgba(0,0,0,.3)",
   			borderRadius: "10px",
   			borderTop: "5px solid #245cb9",
   		}}>
      	<CssBaseline />
      	<div className={classes.paper}>
				<img src={Logomarca} aria-hidden 
					style={{ 
						width: "165px",
						height: "165px",
						paddingTop: "0px",
   					boxShadow: "0px 5px 10px 0px rgba(0,0,0,.3)",
   					border: "1px solid #eee",
   					objectFit: "scale-down",
   					borderRadius: "100%"
					}} alt={"logo"} />
        		<Typography component="h1" variant="h5" style={{marginTop:"5px",textAlign:"center",color:"#050512"}}>
          		{i18n.t("login.title")}<br />WhatsApp NEO ORTODONTIA
        		</Typography>
        		<form className={classes.form} noValidate onSubmit={handlSubmit}>
          		<TextField style={{ fontSize: "2.5em"}}
            		variant="outlined"
            		margin="normal"
            		required
            		fullWidth
            		id="email"
            		label={i18n.t("login.form.email")}
            		name="email"
            		size="small"
            		value={user.email}
            		onChange={handleChangeInput}
            		autoComplete="email"	autoFocus 
            		InputProps={{
              		endAdornment: (
                		<InputAdornment position="end">
	                  	<IconButton>
	                    		<Email />
	                  	</IconButton>
                		</InputAdornment>
              		)
            	}} />
          		
          		<TextField style={{ fontSize: "2.5em"}}
            		variant="outlined"
            		margin="normal"
            		required
            		fullWidth
            		name="password"
            		label={i18n.t("login.form.password")}
            		id="password"
            		size="small"
            		value={user.password}
            		onChange={handleChangeInput}
            		autoComplete="current-password"
            		type={showPassword ? 'text' : 'password'}
            		InputProps={{
              		endAdornment: (
                		<InputAdornment position="end">
	                  	<IconButton
	                    		aria-label="toggle password visibility"
	                    		onClick={() => setShowPassword((e) => !e)}>
	                    		{showPassword ? <VisibilityOff /> : <Visibility />}
	                  	</IconButton>
                		</InputAdornment>
              		)
            	}} />
          		<Button style={{ textTransform: "capitalize", fontFamily: "Verdana", fontSize: "1.2em"}}
            		type="submit"
            		fullWidth
            		variant="contained"
            		color="primary"
            		className={classes.submit}>
            		<CheckBoxIcon style={{ fontSize: "1.2em", marginRight: "3px"}} />
            		{i18n.t("login.buttons.submit")}
          		</Button>
        		</form>
      	</div>
      	<Box mt={8}><Copyright /></Box>
      	<br />
    	</Container>
  	);
};
export default Login;